<template>
  <nav class="navbar">
    <div class="logo-container">
      <router-link to="/" class="logo" @click="$router.push('/')">
        <img src="../assets/paanguzo.png" alt="Logo" />
      </router-link>
    </div>

    <div class="navigation">
      <router-link v-if="!isLoggedIn" to="/login" class="nav-link"> Login </router-link>
      <router-link v-if="!authToken" to="/signup" class="nav-link"> Register </router-link>
      <a v-if="isLoggedIn" href="#" class="logout" @click="logout">Logout</a>
      <router-link v-if="userId" :to="'/profile/' + userId" class="nav-link">My Profile</router-link>
      <a href="#" class="faq" @click="toggleModal">
        <i class="bi bi-bell"></i>
        <span class="badge">{{ notificationsCount }}</span>
      </a>
    </div>

    <nav class="mobile-menu">
      <ul class="mobile-menu-list">
        <li class="dropdown">
          <a href="#" class="dropdown-toggle">
            <i class="fa-solid fa-bars"></i> Menu
          </a>
          <ul class="dropdown-menu">
            <li><a @click="$router.push('/')"><i class="fa-solid fa-id-card"></i> Customer</a></li>
            <li><a href="#"><i class="fa fa-bullhorn"></i> Marketing</a></li>
            <li><a @click="$router.push('/leads')"><i class="fa fa-chart-line"></i> Sales</a></li>
            <li><a @click="$router.push('/tickets')"><i class="fa fa-tag"></i> Service</a></li>
            <li><a @click="$router.push('/dashboard')"><i class="fa fa-ticket-alt"></i> Dashboards</a></li>
            <li><a @click="$router.push('/settings')"><i class="fas fa-cog"></i> Settings</a></li>
          </ul>
        </li>
      </ul>
    </nav>

    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="showModal = false">&times;</span>
        <h2>Chat</h2>
        <div id="chatbox">
          <div id="chatlogs">
            <p v-for="(message, index) in messages" :key="index">{{ message }}</p>
          </div>
          <div id="chat-form">
            <textarea id="chat-input" v-model="input" placeholder="Type your message..."></textarea>
            <button id="send-button" @click="sendMessage">Send</button>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <Modal :show-modal="isModalVisible" @close-modal="closeModal">
    <template #header>
      <h2>{{ modalHeader }}</h2>
    </template>
    <template #body>
      <NotificationsPage />
      <ChatPage />
    </template>
    <template #footer />
  </Modal>
</template>
<script>
  import axios from "@/utils/axios";
  import { computed, onMounted, ref, reactive } from "vue";
  import { useRoute } from "vue-router";
  import Modal from "@/components/Modal.vue";
  import ChatPage from "@/views/Social/ChatPage.vue";
  import NotificationsPage from "@/views/configs/NotificationsPage.vue";

  export default {
    name: "NavbarPage",

    components: {
      Modal,
      ChatPage,
      NotificationsPage,
    },

    setup() {
      const authToken = computed(() => localStorage.getItem("appToken"));
      const userId = ref(null);
      const isLoggedIn = computed(() => authToken.value !== null);
      const showModal = ref(false);
      const isModalVisible = ref(false);
      const modalHeader = ref("Chat with " + userId.value);
      const notificationsCount = ref("0");

      const getNotifications = async () => {
        try {
          const response = await axios.get("/notifications/notifications/");
          notificationsCount.value = response.data.count;
        } catch (error) {
          console.error("Error:", error.response.status);
        }
      };

      const toggleModal = () => {
        isModalVisible.value = !isModalVisible.value;
      };

      const closeModal = () => {
        isModalVisible.value = false;
      };

      const logout = () => {
        localStorage.clear();
        window.location.reload();
      };

      onMounted(async () => {
        if (isLoggedIn.value) {
          await getNotifications();
          try {
            const response = await axios.get("/accounts/users/me/");
            userId.value = response.data.id;
          } catch (error) {
            console.error("Error:", error.response.status);
          }
        }
      });

      const input = ref("");
      const messages = reactive([]);
      const sendMessage = () => {
        messages.push("You: " + input.value);
        input.value = "";
        setTimeout(() => {
          messages.push("Bot: This is still work in progress.");
        }, 1000);
      };

      return {
        authToken,
        userId,
        isLoggedIn,
        logout,
        showModal,
        toggleModal,
        isModalVisible,
        closeModal,
        modalHeader,
        notificationsCount,
        messages,
        input,
        sendMessage,
      };
    },
  };
</script>
